<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Update Man-day Cost</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="pt-0" v-if="errors && errors.message">
                            <v-alert dense outlined type="error" class="mb-0"><span v-text="errors.message"></span></v-alert>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense readonly label="Current Man-day Cost" v-model="object.current_cost"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense label="New Man-day Cost*" :rules="rules.cost" :error-messages="errors.cost" v-model="object.new_cost"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text :disabled="loading" @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

export default {
	name: 'CampaignUpdateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                year: '',
                month: '',
                current_cost: '',
                new_cost: ''
			},
			rules: {
				cost: [
                    (value) => {
                        if(!value) {
                            return 'New man-day cost is required'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Cost must be numeric value'
                        }

                        return true
                    }
                ]
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.reset()
            this.form.resetValidation()
            this.object = { year: '', month: '', current_cost: '', new_cost: '' }
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = { year: item.year, month: item.month, current_cost: item.current_cost, new_cost: '' }
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
				const object = { year: this.object.year, month: this.object.month, cost: this.object.new_cost }
                this.$store.dispatch('epanel/report/updateReportCampaignMonthly', object).then((response) => {
                    this.$emit('updated', { object: response.data.object, message: response.message })
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data?errors.data.errors:errors
                    this.loading = false
                })
			}
		}
	}
}

</script>