<template>
    <div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Monthly Campaign Sales Reports</p>
                <p class="text-title mb-0">You do not have permission to view monthly campaign sales report</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-2">Monthly Campaign Report</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <v-select dense hide-details label="Year" class="my-3" :items="years" :menu-props="{top: false, offsetY: true}" v-model="filters.year"></v-select>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" order="last">
                                <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="search">
                                    <v-icon small left>mdi-magnify</v-icon> View
                                </v-btn>
                                <v-btn small class="mt-2 elevation-0" :ripple="false" @click='reset'>
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" v-if="permissions.can_download">
                    <v-btn small color="primary" class="mr-2 mt-2 elevation-0" :ripple="false" @click="openDownloadDialog">
                        <v-icon small left>mdi-download</v-icon> Download
                    </v-btn>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <p class="text-center font-weight-bold">Summary of Manhours Deployed <span v-text="object.year"></span></p>
                    <v-data-table dense hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="20" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span :class="{'pointer blue--text text-decoration-underline': permissions.can_reset && item.month.value }" v-text="item.month.text" @click="openGenerateDialog(item, index)"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.man_days"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.man_hours"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_sales"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.day_sales"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <span :class="{'pointer blue--text text-decoration-underline': item.can_edit && item.day_cost !== '-' }" v-text="item.day_cost" @click="openUpdateDialog(item, index)"></span>
                                </td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.total_cost"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.roi"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.roi_percentage"></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <small class="red--text">Note:<br>
                    1. Every month's data will be generated on 1st of every next month. Eg: The data in March will be generated on 1st of April.<br>
                    2. Data can be re-generate for the latest 2 months only.<br>
                    3. Man-day Cost/Day will be reset to 120/man-day for each generates.<br>
                    </small>
                </v-col>
            </v-row>
        </template>
        <update-dialog ref="updateDialog" @updated="updateReport"></update-dialog>
        <generate-dialog ref="generateDialog" @generated="updateReport"></generate-dialog>
        <download-dialog ref="downloadDialog" @downloaded="openLoadingDialog"></download-dialog>
        <simple-action-dialog :has-cancel="false" ref="loadingDialog" title="Preparing..." text="System is preparing the report now. Please be patient." confirm-text="OK"></simple-action-dialog>
        <message-notifier ref="notifier"></message-notifier>
    </div>
</template>


<script>

import { makeYearList } from '@/utils/dates'
import CampaignDownloadDialog from './CampaignDownloadDialog.vue'
import CampaignUpdateDialog from './CampaignUpdateDialog.vue'
import CampaignGenerateDialog from './CampaignGenerateDialog.vue'
import SimpleActionDialog from '../../../../shared/SimpleActionDialog.vue'
import MessageNotifier from '../../../../shared/MessageNotifier.vue'

export default {
    name: 'CampaignReportList',
    components: {
        simpleActionDialog: SimpleActionDialog,
        updateDialog: CampaignUpdateDialog,
        generateDialog: CampaignGenerateDialog,
        downloadDialog: CampaignDownloadDialog,
        messageNotifier: MessageNotifier,
    },
    data() {
        return {
            loading: true,
            selectedIndex: -1,
            headers: [],
            items: [],
            filters: {
                year: new Date().getFullYear().toString()
            },
            object: {
                year: new Date().getFullYear().toString(),
            },
            permissions: {
                can_list: false,
                can_reset: false,
                can_download: false
            }
        }
    },
    computed: {
        years() {
            return makeYearList(2021)
        },
        updateDialog() {
            return this.$refs.updateDialog
        },
        generateDialog() {
            return this.$refs.generateDialog
        },
        downloadDialog() {
            return this.$refs.downloadDialog
        },
        loadingDialog() {
            return this.$refs.loadingDialog
        },
        notifier() {
            return this.$refs.notifier
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        get: function() {
            this.loading = true
            this.$store.dispatch('epanel/report/getReportCampaignMonthly', this.filters).then((response) => {
                this.items = response.data.items
                this.headers = response.data.headers
                this.object = response.data.object
                this.permissions = response.data.permissions
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        search: function() {
            this.get()
        },
        reset: function() {
            this.filters = { year: new Date().getFullYear().toString() }
        },
        openUpdateDialog: function(item, index) {
            if(this.permissions.can_edit && item.day_cost !== '-') {
                this.selectedIndex = index
                this.updateDialog.updateObject({ year: this.object.year, month: index + 1, current_cost: item.day_cost})
                this.updateDialog.open()
            }
        },
        openGenerateDialog: function(item, index) {
            if(this.permissions.can_reset && item.month.value){
                this.selectedIndex = index
                this.generateDialog.updateObject({ text: item.month.full_text, value: item.month.value })
                this.generateDialog.open()
            }
        },
        openDownloadDialog: function() {
            if(this.permissions.can_download){
                this.downloadDialog.updateObject(this.object)
                this.downloadDialog.open()
            }
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        openLoadingDialog: function(){
            this.loadingDialog.open()
        },
        updateReport: function(item){
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
        }
    }
}

</script>