<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-form ref="form" action="/report/campaign/monthly/download/">
            <v-card>
                <v-card-title>Download Report</v-card-title>
                <v-card-text class="pb-0 my-1">
                    <input type="hidden" name="year" :value="object.year" />
                    <span class="text-subtitle-1">Are you sure that you want to download the report?</span>
                </v-card-text>
                <v-card-actions class="px-4">
                    <v-spacer></v-spacer>
                    <v-btn text @click="close">Later</v-btn>
                    <v-btn text color="primary" @click="download">Download</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>


<script>

export default {
    name: 'CampaignDownloadDialog',
    data() {
        return {
            dialog: false,
            object: {}
        }
    },
    computed: {
        form() {
            return this.$refs.form
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        updateObject: function(item) {
            this.object = item
        },
        download: function() {
            this.form.$el.submit()
            this.close()
            this.$emit('downloaded')
        },
    }
}

</script>