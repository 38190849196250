<template>
    <v-dialog persistent max-width="764" v-model="dialog">
        <v-card>
            <v-card-title v-html="title" v-if="title"></v-card-title>
            <v-card-text class="pb-0">
                <span class="text-subtitle-1" v-html="text"></span>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="close" :disabled="loading" v-text="cancelText" v-if="hasCancel"></v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" :loading="loading" @click="action" v-text="confirmText" v-if="hasConfirm"></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'SimpleActionDialog',
    props: {
        title: {
            type: String,
            required: false,
            default: 'Title'
        },
        text: {
            type: String,
            required: false,
            default: 'Text'
        },
        confirmText: {
            type: String,
            required: false,
            default: 'Confirm Text'
        },
        cancelText: {
            type: String,
            required: false,
            default: 'Cancel'
        },
        hasCancel: {
            type: Boolean,
            required: false,
            default: true
        },
        hasConfirm: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            dialog: false,
            data: {}
        }
    },
    methods: {
        open: function() {
            this.dialog = true
        },
        close: function() {
            this.dialog = false
        },
        action: function() {
            this.$emit('action', this.data)
            this.close()
        }
    }
}

</script>